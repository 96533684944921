
import { defineComponent, onMounted, ref } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';

import {
  useSupports,
  useDeleteSupport,
  useSolveSupport,
  useArchiveSupport,
  useRecoverSupport
} from '@/composables/api';
import { SupportIdOptions } from '@/services/api';

import { SupportStatus } from '@/interfaces/Support';
import SearchBox from '@/components/keyword-searching/Index.vue';
import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';
import permissionUnits from '@/components/permission-units/index.vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

const FILTER_OPTIONS: FilterOption[] = [
  {
    type: FilterType.SELECTOR,
    label: 'Status',
    placeholder: 'Please select an option',
    options: [
      {
        label: 'Opening',
        value: SupportStatus.OPENING
      },
      {
        label: 'Solved',
        value: SupportStatus.SOLVED

      }
    ]
  }
];

export default defineComponent({
  components: {
    SearchBox,
    Filter,
    permissionUnits
  },
  setup() {
    const page = ref(1);
    const keyword = ref();
    const status = ref();
    const isArchived = ref();
    const route = useRoute();
    const { t } = useI18n();

    const sortBy = ref();
    const descSortBy = ref('updated_at');

    const { data, isLoading, isFetching, refetch } = useSupports({ page, keyword, status, isArchived, sortBy, descSortBy });
    const { isLoading: isDeletedLoading, mutate } = useDeleteSupport();
    const { isLoading: isRecoveringLoading, mutate: recover } = useRecoverSupport();
    const { isLoading: isSolvedLoading, mutate: solveMutate } =
      useSolveSupport();
    const { isLoading: isArchivedLoading, mutate: archiveMutate } =
      useArchiveSupport();
    const statusIndex = ref(undefined);

    const sortChange = ({ prop, order }) => {
      if (!prop) {
        descSortBy.value = '';
        sortBy.value = '';
        return;
      }

      if (order === 'ascending') {
        sortBy.value = prop;
        descSortBy.value = '';
      } else {
        descSortBy.value = prop;
        sortBy.value = '';
      }
    };

    const searchKeyword = (_keyword: string) => {
      keyword.value = _keyword;
    };
    const handleFilterChange = (event: FilterEvent) => {
      // mutate ref
      status.value = event[0];
    };

    const toggleArchivedData = () => {
      // eslint-disable-next-line @typescript-eslint/camelcase
      if (!isArchived.value) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        isArchived.value = true;
      } else {
        // eslint-disable-next-line @typescript-eslint/camelcase
        isArchived.value = null;
      }
    };

    onMounted(() => {
      const routeQuery = route.query;

      if (routeQuery.page && (routeQuery.isEdited !== '1')) {
        page.value = Number(route.query.page);
      }
    });

    const recoverSupport = ({ supportId }: SupportIdOptions, index: Number) => {
      statusIndex.value = index;
      ElMessageBox.confirm('Are you sure you want to recover?', 'Warning', {
        confirmButtonText: 'Recover',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(() => {
          recover(
            { supportId },
            {
              onSuccess() {
                refetch.value();

                ElMessage({
                  type: 'success',
                  message: 'Recover successfully'
                });
              },
              onError(error: any) {
                ElMessage({
                  message: error.response?.data.message,
                  type: 'error'
                });
              }
            }
          );
        })
        .catch();
    };

    const deleteSupport = ({ supportId }: SupportIdOptions, index: Number) => {
      statusIndex.value = index;
      ElMessageBox.confirm('Are you sure you want to delete?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(() => {
          mutate(
            { supportId },
            {
              onSuccess() {
                refetch.value();

                ElMessage({
                  type: 'success',
                  message: 'Delete successfully'
                });
              },
              onError(error: any) {
                ElMessage({
                  message: error.response?.data.message,
                  type: 'error'
                });
              }
            }
          );
        })
        .catch();
    };

    const solveSupport = ({ supportId }: SupportIdOptions, index: Number) => {
      statusIndex.value = index;
      solveMutate(
        { supportId },
        {
          onSuccess() {
            refetch.value();

            ElMessage({
              type: 'success',
              message: 'Solve successfully'
            });
          },
          onError(error: any) {
            ElMessage({
              message: error.response?.data.message,
              type: 'error'
            });
          }
        }
      );
    };

    const archiveSupport = ({ supportId }: SupportIdOptions, index: Number) => {
      statusIndex.value = index;
      archiveMutate(
        { supportId },
        {
          onSuccess() {
            refetch.value();

            ElMessage({
              type: 'success',
              message: 'Archive successfully'
            });
          },
          onError(error: any) {
            ElMessage({
              message: error.response?.data.message,
              type: 'error'
            });
          }
        }
      );
    };

    return {
      page,
      data,
      isLoading,
      isFetching,
      statusIndex,
      isArchived,
      SupportStatus,
      FILTER_OPTIONS,
      isDeletedLoading,
      isSolvedLoading,
      isArchivedLoading,
      isRecoveringLoading,
      sortChange,
      solveSupport,
      deleteSupport,
      searchKeyword,
      recoverSupport,
      archiveSupport,
      handleFilterChange,
      t,
      toggleArchivedData
    };
  }
});
